import { render, staticRenderFns } from "./MBF.vue?vue&type=template&id=64f53a62&scoped=true&"
import script from "./MBF.vue?vue&type=script&lang=js&"
export * from "./MBF.vue?vue&type=script&lang=js&"
import style0 from "./MBF.vue?vue&type=style&index=0&id=64f53a62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f53a62",
  null
  
)

export default component.exports